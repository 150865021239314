import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'assets/css/index.css';

import 'aos/dist/aos.css';

export default function TopLayout(props) {
  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: 'en'
        }}
        title={'Qeerio Guestlist | Leading guest list application for event check-in.'}
      >
        <meta charSet="utf-8" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        {/* <link rel="shortcut icon" href="/assets/favicon.ico" /> */}
        <meta name="theme-color" content="#ff6453" />
        <meta
          name="description"
          content="Qeerio Guestlist is the leading guest list app to check-in your guest on event. Get started in seconds.Free up to 50 guests. No automatic renewal."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={require('../../src/assets/images/qeerio/imagepricing.webp').default}
        />
        <meta
          property="og:title"
          content="Qeerio Guestlist | Leading guest list application for event check-in."
        />
        <meta
          property="og:description"
          content="Qeerio Guestlist is the leading guest list app to check-in your guest on event. Get started in seconds.Free up to 50 guests. No automatic renewal."
        />
        <meta property="og:url" content="https://guestlist.qeerio.com" />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap"
          rel="preload"
          onload="this.onload=null;this.rel='stylesheet'"
          as="style"
        />
        <script
          src="https://kit.fontawesome.com/4c273e6d43.js"
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      {props.children}
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
